@import 'leaflet.css';
@charset "UTF-8";
@font-face {
  font-family: "argon";
  src: url("fonts/argon.eot");
  src: url("fonts/argon.eot?#iefix") format("embedded-opentype"), url("fonts/argon.woff") format("woff"), url("fonts/argon.ttf") format("truetype"), url("fonts/argon.svg#argon") format("svg");
  font-weight: normal;
  font-style: normal;
}
.corpsmap {
  height: 100%;
  position: relative;
  width: 100%;
}
.corpsmap * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.corpsmap .clearfix:after,
.corpsmap .clearfix:before {
  content: " ";
  display: table;
}
.corpsmap .clearfix:after {
  clear: both;
}
.hidden {
  display: none;
}
.leaflet-control-zoomdisplay div {
  font-family: "argon" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.leaflet-control-fullscreen a {
  font-family: "argon" !important;
  background-size: 26px 52px;
}
.leaflet-control-fullscreen a:after {
  content: "\67";
}
.leaflet-fullscreen-on .leaflet-control-fullscreen a:after {
  content: "\68";
}
.leaflet-container:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
}
.leaflet-container.leaflet-fullscreen-on {
  width: 100% !important;
  height: 100% !important;
}
.leaflet-pseudo-fullscreen {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 99999;
}
.corpsmap-control-measure button.leaflet-bar-single {
  font-family: "argon" !important;
}
.corpsmap-control-measure button.leaflet-bar-single:after {
  content: "\61";
}
.corpsmap-control-measure ul {
  display: none;
  height: 28px;
  left: 26px;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  z-index: -1;
}
.corpsmap-control-measure ul li {
  display: inline-block;
}
.corpsmap-control-measure ul li:first-child a {
  border-left: none;
}
.corpsmap-control-measure ul li:last-child {
  vertical-align: middle;
}
.corpsmap-control-measure ul li select {
  margin-left: 5px;
}
.corpsmap-control-measure ul li select:first-child {
  display: none;
}
.corpsmap-control-measure ul li button {
  background-color: #fff;
  border: none !important;
  border-radius: 0 !important;
  color: #444 !important;
  display: block !important;
  font-size: 11px !important;
  height: 28px !important;
  line-height: 28px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-align: center !important;
  text-decoration: none !important;
  width: auto !important;
}
.corpsmap-control-measure ul li button:hover {
  background-color: #fff !important;
}
.corpsmap-control-measure ul li button.pressed {
  background-color: #fff !important;
}
.corpsmap-control-measure ul li:last-child button {
  border-bottom-right-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.leaflet-draw-tooltip {
  visibility: hidden !important;
}
.leaflet-draw-tooltip:before {
  visibility: hidden !important;
}
.leaflet-error-draw-tooltip {
  background-color: #f2dede;
  border: 1px solid #e6b6bd;
  color: #b94a48;
  font-size: 11px;
  padding: 5px;
  visibility: visible !important;
}
.corpsmap .leaflet-measure-tooltip {
  background-color: #fff;
  background-color: rgba(255,255,255,0.7);
  box-shadow: 0 0 5px #bbb;
  font-size: 11px;
  height: auto !important;
  margin: 0;
  padding: 2px;
  white-space: nowrap;
  width: auto !important;
}
.corpsmap .leaflet-measure-tooltip div.leaflet-measure-tooltip-distance span:last-child {
  display: none;
}
.corpsmap .leaflet-measure-tooltip .leaflet-measure-tooltip-difference {
  color: #777;
}
.corpsmap .leaflet-measure-tooltip .leaflet-measure-tooltip-total {
  font-weight: bold;
}
.corpsmap .leaflet-control-home button {
  font-family: "argon" !important;
}
.corpsmap .leaflet-control-home button:after {
  content: "\65";
}
.corpsmap .leaflet-control-identify button {
  font-family: "argon" !important;
}
.corpsmap .leaflet-control-identify button:after {
  content: "p";
}
.corpsmap .leaflet-control-geocoder button {
  font-family: "argon" !important;
}
.corpsmap .leaflet-control-geocoder button:after {
  content: "\6b";
}
.geocoder-control-input-container {
  display: none;
  position: absolute;
  left: 32px;
  width: 210px !important;
  border-radius: 4px;
}
.geocoder-control-clear {
  cursor: pointer;
  padding-left: 6px;
  padding-right: 6px;
}
.geocoder-control-input {
  width: 90%;
}
.geocoder-results-list {
  list-style: none;
  position: absolute;
  text-align: left;
  color: #444;
  top: 15px;
  left: 20px;
  background-color: #fff;
  padding: 0;
  max-height: 600px;
  overflow: auto;
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);
}
.geocoder-results-list-item {
  color: #444;
  padding-left: 6px;
  padding-right: 6px;
  cursor: pointer;
}
.geocoder-results-list-item:hover {
  background-color: #f6f6f6;
}
.leaflet-control-layers-toggle {
  font-size: 18px;
}
.leaflet-control-layers {
  background-color: #fff;
  color: #444;
}
.corpsmap .leaflet-control-layers a {
  background-color: #fff;
  border-bottom: 1px solid #262b2b;
  border-left: 1px solid #262b2b;
  border-right: 1px solid #262b2b;
  border-top: none;
  border-radius: 4px;
  color: #444;
  height: 27px;
  line-height: 26px;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: 26px;
  background-image: none;
  font-family: "argon" !important;
}
.corpsmap .leaflet-control-layers a:after {
  content: "\6a";
}
.leaflet-control-minimap {
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);
  border-radius: 4px;
  border: 1px solid #262b2b;
}
.corpsmap-title-container {
  z-index: 99999;
  position: absolute;
  width: 100%;
  background-color: #fff;
  opacity: 0.75;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}
.corpsmap-title-title {
  font-size: 2.5em;
  color: #444;
}
.corpsmap-title-subtitle {
  font-size: 1.3em;
  color: #444;
}
.corpsmap .leaflet-control-copy button {
  font-size: 1em !important;
}
.copy-lon-lat-preview-container {
  display: none;
  position: absolute;
  left: 32px;
  width: 200px !important;
  height: 70px !important;
  border-radius: 4px;
}
.copy-lon-lat-help-text {
  width: 100% !important;
  border: none !important;
  height: 40px !important;
  font-size: 12px !important;
  line-height: normal !important;
  padding: 5px 0 !important;
}
.copy-lon-lat-preview {
  width: 150px;
  margin: 0 auto;
  background-color: #fff;
  border: none;
  color: #444;
  font-weight: bold;
  text-align: center;
}
.identify-popup-pager {
  margin: 0;
  padding: 0;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
}
.identify-popup-pager-item {
  cursor: pointer;
  list-style: none;
  width: 15px;
  text-align: center;
  margin-right: 3px;
  margin-left: 3px;
}
.identify-popup-pager-item.active {
  background-color: #ccc;
}
.corpsmap .leaflet-control-zoom a.leaflet-disabled {
  cursor: not-allowed !important;
}
#map.alias {
  cursor: alias;
}
#map.all-scroll {
  cursor: all-scroll;
}
#map.auto {
  cursor: auto;
}
#map.cell {
  cursor: cell;
}
#map.context-menu {
  cursor: context-menu;
}
#map.col-resize {
  cursor: col-resize;
}
#map.copy {
  cursor: copy;
}
#map.crosshair {
  cursor: crosshair;
}
#map.default {
  cursor: default;
}
#map.e-resize {
  cursor: e-resize;
}
#map.ew-resize {
  cursor: ew-resize;
}
#map.grab {
  cursor: grab;
}
#map.grabbing {
  cursor: grabbing;
}
#map.help {
  cursor: help;
}
#map.move {
  cursor: move;
}
#map.n-resize {
  cursor: n-resize;
}
#map.ne-resize {
  cursor: ne-resize;
}
#map.nesw-resize {
  cursor: nesw-resize;
}
#map.ns-resize {
  cursor: ns-resize;
}
#map.nw-resize {
  cursor: nw-resize;
}
#map.nwse-resize {
  cursor: nwse-resize;
}
#map.no-drop {
  cursor: no-drop;
}
#map.none {
  cursor: none;
}
#map.not-allowed {
  cursor: not-allowed;
}
#map.pointer {
  cursor: pointer;
}
#map.progress {
  cursor: progress;
}
#map.row-resize {
  cursor: row-resize;
}
#map.s-resize {
  cursor: s-resize;
}
#map.se-resize {
  cursor: se-resize;
}
#map.sw-resize {
  cursor: sw-resize;
}
#map.text {
  cursor: text;
}
#map.URL {
  cursor: URL;
}
#map.vertical-text {
  cursor: vertical-text;
}
#map.w-resize {
  cursor: w-resize;
}
#map.wait {
  cursor: wait;
}
#map.zoom-in {
  cursor: zoom-in;
}
#map.zoom-out {
  cursor: zoom-out;
}
.corpsmap .leaflet-zoom-box {
  border: 2px dashed #c8e6c9;
  background: #fff;
  opacity: 0.5;
}
.corpsmap .leaflet-bar button {
  display: inherit;
}
.corpsmap .leaflet-bar a,
.corpsmap .leaflet-bar a:hover,
.corpsmap .leaflet-bar button,
.corpsmap .leaflet-bar button:hover,
.corpsmap .leaflet-bar div,
.corpsmap .leaflet-bar div:hover {
  background-color: #fff;
  border-bottom: 1px solid #262b2b;
  border-left: 1px solid #262b2b;
  border-right: 1px solid #262b2b;
  border-top: none;
  color: #444;
  height: 27px;
  line-height: 26px;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: 26px;
  font-size: 18px;
}
.corpsmap .leaflet-bar a:hover,
.corpsmap .leaflet-bar button:hover {
  background-color: #f6f6f6;
}
.corpsmap .leaflet-bar a:active,
.corpsmap .leaflet-bar a.pressed,
.corpsmap .leaflet-bar button:active,
.corpsmap .leaflet-bar button.pressed {
  background-color: #e3e3e3;
}
.corpsmap .leaflet-bar a.leaflet-disabled,
.corpsmap .leaflet-bar a.leaflet-disabled:hover,
.corpsmap .leaflet-bar button.leaflet-disabled,
.corpsmap .leaflet-bar button.leaflet-disabled:hover {
  background-color: #b9b9b9;
  color: #444;
}
.corpsmap .leaflet-bar a:first-child,
.corpsmap .leaflet-bar button:first-child,
.corpsmap .leaflet-bar div:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top: 1px solid #262b2b;
  height: 26px;
}
.corpsmap .leaflet-bar a:last-child,
.corpsmap .leaflet-bar button:last-child,
.corpsmap .leaflet-bar div:last-child {
  border-bottom: 1px solid #262b2b;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
  height: 27px;
}
.corpsmap .leaflet-bar a.leaflet-bar-single,
.corpsmap .leaflet-bar button.leaflet-bar-single,
.corpsmap .leaflet-bar div.leaflet-bar-single {
  border-radius: 4px;
  border-top: solid 1px #262b2b;
  height: 26px;
}
.legend {
  background-color: rgba(255,255,255,0.5);
  border-radius: 5px;
  line-height: 18px;
  color: #555;
  height: 60px;
  overflow: hidden;
  padding: 5px;
/*resize: both*/
/*TODO make draggable?*/
}
.legend-label {
  font-style: italic;
}
.legend-hidden {
  display: none;
}
.legend:hover,
.legend-expanded {
  background-color: rgba(255,255,255,0.75);
  height: auto;
  max-height: 500px;
  overflow-y: scroll;
}
.legend-header {
  font-size: 14px;
  font-weight: bold;
}
.legend-button {
  cursor: pointer;
  float: right;
  font-family: 'argon';
}
.legend-button:before {
  content: '\67';
}
.legend-button-locked:before {
  content: '\6D';
}
.legend-img {
  border-radius: 5px;
  opacity: 0.7;
}
.legend-style-list {
  font-size: 14px;
  list-style: none;
  padding-left: 6px;
}
.legend-style-list-item {
  list-style: none;
  padding: 0;
  padding-top: 5px;
  padding-left: 20px;
  margin: 0;
}
.legend-display {
  position: absolute;
  top: 45px;
  left: 10px;
  bottom: 0;
  right: 3px;
  overflow: auto;
}
.legendPanel-itemList {
  list-style: none;
  padding: 0;
  padding-top: 5px;
  margin-top: 5px;
  margin-left: 5px;
}
.legend-results-item-value {
  padding-bottom: 5px;
  font-size: 0.8em;
}
